<template>
  <LiefengContent>
    <template v-slot:toolsbarLeft> </template>
    <template v-slot:toolsbarRight>
      <Button
        v-if="isCustom"
        type="info"
        size="large"
        icon="md-eye"
        style="width: 150px"
        @click="viewReport"
        >报表预览</Button
      >
      <Button
        v-if="!isCustom"
        type="default"
        size="large"
        icon="ios-arrow-back"
        style="width: 150px"
        @click="backReport"
        >返回定制</Button
      >
      <Button
        v-if="!isCustom"
        type="success"
        size="large"
        icon="md-checkmark"
        style="width: 150px"
        @click="saveReport"
        >保存报表</Button
      >
    </template>
    <template v-slot:contentArea>
      <List class="clist" v-if="isCustom">
        <ListItem>
          <div class="ctext">横标（表头）：</div>
          <RadioGroup v-model="columnSlt.slt" @on-change="changeColumnSlt">
            <Radio
              v-for="(value, key) in indexList"
              :key="'R' + value"
              :value="value"
              :label="key"
              border
              :disabled="rowSlt.slt == key ? true : false"
            ></Radio>
          </RadioGroup>
        </ListItem>
        <ListItem>
          <Tree
            ref="cList"
            :data="columnList"
            @on-select-change="selectChangeColumn"
            :multiple="columnSlt.slt == '时间' ? false : true"
            class="tree"
          ></Tree>
        </ListItem>
        <ListItem>
          <div class="ctext">纵标（行）：</div>
          <RadioGroup v-model="rowSlt.slt" @on-change="changeRowSlt">
            <Radio
              v-for="(value, key) in indexList"
              :key="'R' + value"
              :value="value"
              :label="key"
              border
              :disabled="columnSlt.slt == key ? true : false"
            ></Radio>
          </RadioGroup>
        </ListItem>
        <ListItem>
          <Tree
            ref="rList"
            :data="rowList"
            @on-select-change="selectChangeRow"
            :multiple="rowSlt.slt == '时间' ? false : true"
            class="tree"
          ></Tree>
        </ListItem>
        <ListItem>
          <div class="ctext">图表类型：</div>
          <RadioGroup v-model="chartSlt.slt">
            <Radio
              v-for="(value, key) in chartList"
              :key="'CH' + value"
              :value="value"
              :label="key"
              border
              ><Icon
                :type="
                  value == 1
                    ? 'md-pulse'
                    : value == 2
                    ? 'md-stats'
                    : value == 3
                    ? 'md-pie'
                    : ''
                "
              />{{ key }}</Radio
            >
          </RadioGroup>
        </ListItem>
      </List>
      <List class="clist" v-else>
        <ListItem>
          报表名称：
          <Input
            placeholder="50字以内"
            maxlength="50"
            show-word-limit
            required
            style="width: calc(100% - 150px)"
          ></Input>
        </ListItem>
        <ListItem>
          报表描述：
          <Input
            placeholder="200字以内"
            maxlength="200"
            show-word-limit
            type="textarea"
            required
            style="width: calc(100% - 150px)"
          ></Input>
        </ListItem>
        <ListItem> 是否共享： <Checkbox></Checkbox> </ListItem>
        <Card>
          <div slot="title" class="searchStyle">
            <div
              v-if="
                indexList[columnSlt.slt] != indexList['时间'] &&
                indexList[rowSlt.slt] != indexList['时间']
              "
              class="searchTab"
            >
              日期：<Select v-model="reportDateType" style="width: 100px">
                <Option
                  v-for="(item, index) in dataList['时间']"
                  :key="'T' + index"
                  :value="item.id"
                  >{{ item.title }}</Option
                >
              </Select>

              <DatePicker
                v-if="reportDateType > 0"
                :type="
                  reportDateType == 1 || reportDateType == 2
                    ? 'year'
                    : reportDateType == 5
                    ? 'date'
                    : 'month'
                "
                placeholder="选择日期"
                style="width: 150px"
              ></DatePicker>

              <Select v-if="reportDateType == 2" style="width: 100px">
                <Option value="1">第一季</Option>
                <Option value="2">第二季</Option>
                <Option value="3">第三季</Option>
                <Option value="4">第四季</Option>
              </Select>

              <Select v-if="reportDateType == 4" style="width: 100px">
                <Option value="1">第一周</Option>
                <Option value="2">第二周</Option>
                <Option value="3">第三周</Option>
                <Option value="4">第四周</Option>
              </Select>
            </div>

            <div
              v-if="
                indexList[columnSlt.slt] != indexList['行政区域'] &&
                indexList[rowSlt.slt] != indexList['行政区域']
              "
              class="searchTab"
            >
              行政区域：<Cascader
                :data="dataList['行政区域']"
                change-on-select
                style="width: 200px"
              ></Cascader>
            </div>

            <div
              v-if="
                indexList[columnSlt.slt] != indexList['机构'] &&
                indexList[rowSlt.slt] != indexList['机构']
              "
              class="searchTab"
            >
              机构：<Cascader
                :data="dataList['机构']"
                change-on-select
                style="width: 200px"
              ></Cascader>
            </div>

            <div
              v-if="
                indexList[columnSlt.slt] != indexList['栏目'] &&
                indexList[rowSlt.slt] != indexList['栏目']
              "
              class="searchTab"
            >
              栏目：<Cascader
                :data="dataList['栏目']"
                change-on-select
                style="width: 300px"
              ></Cascader>
            </div>

            <div
              v-if="
                indexList[columnSlt.slt] != indexList['统计指标'] &&
                indexList[rowSlt.slt] != indexList['统计指标']
              "
              class="searchTab"
            >
              统计指标：<Cascader
                :data="dataList['统计指标']"
                change-on-select
                style="width: 200px"
              ></Cascader>
            </div>
            <div class="searchTab">
              <Button
                type="info"
                icon="ios-sea rch"
                style="width: 80px"
                @click="saveReport"
                >查询</Button
              >
            </div>
          </div>
          <Table
            ref="lftable"
            border
            :columns="talbeColumns"
            :data="tableData"
            style="margin: 15px 0"
          >
          </Table>
          <VeLine
            v-if="chartSlt.slt == '折线图'"
            :data="chartData"
            :settings="{ label: { show: true } }"
          ></VeLine>
          <VeHistogram
            v-if="chartSlt.slt == '柱状图'"
            :data="chartData"
            :settings="{ label: { show: true } }"
          ></VeHistogram>
          <VePie
            v-if="chartSlt.slt == '饼状图'"
            :data="chartData"
            :settings="{ label: { show: true } }"
          ></VePie>
        </Card>
      </List>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/reportcustom')
import LiefengContent from "@/components/LiefengContent";
import VeHistogram from "v-charts/lib/histogram.common";
import VeLine from "v-charts/lib/line.common";
import VePie from "v-charts/lib/pie.common.js";

export default {
  created() {
    //特殊处理数据，需要放在读取数据内*****************************************************************************
    for (var key in this.dataList) this.setLabelToSelected(this.dataList[key]);
  },
  mounted() {},
  components: {
    LiefengContent,
    VeHistogram,
    VeLine,
    VePie,
  },
  data() {
    return {
      isCustom: true,
      reportDateType: 0,
      chartData: {
        columns: ["日期", "访问用户", "下单用户"],
        rows: [
          { 日期: "2018-05-22", 访问用户: 32371, 下单用户: 19810 },
          { 日期: "2018-05-23", 访问用户: 12328, 下单用户: 4398 },
          { 日期: "2018-05-24", 访问用户: 92381, 下单用户: 52910 },
        ],
      },
      talbeColumns: [],
      tableData: [
                    {
                        "name": "Name1",
                        "fav": 0,
                        "show": 7302,
                        "weak": 5627,
                        "signin": 1563,
                        "click": 4254,
                        "active": 1438,
                        "day7": 274,
                        "day30": 285,
                        "tomorrow": 1727,
                        "day": 558,
                        "week": 4440,
                        "month": 5610
                    },
                    {
                        "name": "Name2",
                        "fav": 0,
                        "show": 4720,
                        "weak": 4086,
                        "signin": 3792,
                        "click": 8690,
                        "active": 8470,
                        "day7": 8172,
                        "day30": 5197,
                        "tomorrow": 1684,
                        "day": 2593,
                        "week": 2507,
                        "month": 1537
                    },
                    {
                        "name": "Name3",
                        "fav": 0,
                        "show": 7181,
                        "weak": 8007,
                        "signin": 8477,
                        "click": 1879,
                        "active": 16,
                        "day7": 2249,
                        "day30": 3450,
                        "tomorrow": 377,
                        "day": 1561,
                        "week": 3219,
                        "month": 1588
                    },
                    {
                        "name": "Name4",
                        "fav": 0,
                        "show": 9911,
                        "weak": 8976,
                        "signin": 8807,
                        "click": 8050,
                        "active": 7668,
                        "day7": 1547,
                        "day30": 2357,
                        "tomorrow": 7278,
                        "day": 5309,
                        "week": 1655,
                        "month": 9043
                    },
                    {
                        "name": "Name5",
                        "fav": 0,
                        "show": 934,
                        "weak": 1394,
                        "signin": 6463,
                        "click": 5278,
                        "active": 9256,
                        "day7": 209,
                        "day30": 3563,
                        "tomorrow": 8285,
                        "day": 1230,
                        "week": 4840,
                        "month": 9908
                    },
                    {
                        "name": "Name6",
                        "fav": 0,
                        "show": 6856,
                        "weak": 1608,
                        "signin": 457,
                        "click": 4949,
                        "active": 2909,
                        "day7": 4525,
                        "day30": 6171,
                        "tomorrow": 1920,
                        "day": 1966,
                        "week": 904,
                        "month": 6851
                    },
                    {
                        "name": "Name7",
                        "fav": 0,
                        "show": 5107,
                        "weak": 6407,
                        "signin": 4166,
                        "click": 7970,
                        "active": 1002,
                        "day7": 8701,
                        "day30": 9040,
                        "tomorrow": 7632,
                        "day": 4061,
                        "week": 4359,
                        "month": 3676
                    },
                    {
                        "name": "Name8",
                        "fav": 0,
                        "show": 862,
                        "weak": 6520,
                        "signin": 6696,
                        "click": 3209,
                        "active": 6801,
                        "day7": 6364,
                        "day30": 6850,
                        "tomorrow": 9408,
                        "day": 2481,
                        "week": 1479,
                        "month": 2346
                    },
                    {
                        "name": "Name9",
                        "fav": 0,
                        "show": 567,
                        "weak": 5859,
                        "signin": 128,
                        "click": 6593,
                        "active": 1971,
                        "day7": 7596,
                        "day30": 3546,
                        "tomorrow": 6641,
                        "day": 1611,
                        "week": 5534,
                        "month": 3190
                    },
                    {
                        "name": "Name10",
                        "fav": 0,
                        "show": 3651,
                        "weak": 1819,
                        "signin": 4595,
                        "click": 7499,
                        "active": 7405,
                        "day7": 8710,
                        "day30": 5518,
                        "tomorrow": 428,
                        "day": 9768,
                        "week": 2864,
                        "month": 5811
                    }
                ],
      columnSlt: { slt: "", list: [] }, //已选中的横标
      rowSlt: { slt: "", list: [] }, //已选中的纵标
      chartSlt: { slt: "" }, //已选中的图表
      indexList: { 时间: 1, 行政区域: 2, 机构: 3, 栏目: 4, 统计指标: 5 },
      chartList: { 折线图: 1, 柱状图: 2, 饼状图: 3 },
      columnList: [], //当前横标
      rowList: [], //当前纵标
      dataList: {
        时间: [
          {
            title: "年",
            id: 1,
            parentId: 0,
            key:'year',
            expand: true,
            selected: false,
          },
          {
            title: "季",
            id: 2,
            parentId: 0,
            key:'quarter',
            expand: true,
            selected: false,
          },
          {
            title: "月",
            id: 3,
            parentId: 0,
            key:'month',
            expand: true,
            selected: false,
          },
          {
            title: "周",
            id: 4,
            parentId: 0,
            key:'week',
            expand: true,
            selected: false,
          },
          {
            title: "日",
            id: 5,
            parentId: 0,
            key:'day',
            expand: true,
            selected: false,
          },
        ],
        统计指标: [
          {
            title: "全部",
            expand: true,
            selected: false,
            id: 1,
            parentId: 0,

            children: [
              {
                title: "浏览量",
                expand: true,
                selected: false,
                id: 1000,
                parentId: 1,
                children: [
                  {
                    title: "次数",
                    id: 2,
                    parentId: 1000,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "人数",
                    id: 3,
                    parentId: 1000,
                    expand: true,
                    selected: false,
                  },
                ],
              },
              {
                title: "点赞",
                expand: true,
                selected: false,
                id: 4,
                parentId: 1,
                children: [
                  {
                    title: "次数",
                    id: 5,
                    parentId: 4,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "人数",
                    id: 6,
                    parentId: 4,
                    expand: true,
                    selected: false,
                  },
                ],
              },
              {
                title: "分享",
                expand: true,
                selected: false,
                id: 7,
                parentId: 1,
                children: [
                  {
                    title: "次数",
                    id: 8,
                    parentId: 7,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "人数",
                    id: 9,
                    parentId: 7,
                    expand: true,
                    selected: false,
                  },
                ],
              },
              {
                title: "参与",
                expand: true,
                selected: false,
                id: 10,
                parentId: 1,
                children: [
                  {
                    title: "次数",
                    id: 11,
                    parentId: 10,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "人数",
                    id: 12,
                    parentId: 10,
                    expand: true,
                    selected: false,
                  },
                ],
              },
              {
                title: "发布量",
                expand: true,
                selected: false,
                id: 13,
                parentId: 1,
                children: [
                  {
                    title: "已发布",
                    id: 14,
                    parentId: 13,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "已归档",
                    id: 15,
                    parentId: 13,
                    expand: true,
                    selected: false,
                  },
                ],
              },
            ],
          },
        ],
        栏目: [
          {
            title: "全部",
            expand: true,
            selected: false,
            id: 1,
            parentId: 0,

            children: [
              {
                title: "资讯类",
                expand: true,
                selected: false,
                id: 1000,
                parentId: 1,
                children: [
                  {
                    title: "信息",
                    id: 2,
                    parentId: 1000,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "图文",
                    id: 3,
                    parentId: 1000,
                    expand: true,
                    selected: false,
                  },
                ],
              },
              {
                title: "信息发布类",
                expand: true,
                selected: false,
                id: 4,
                parentId: 1,
                children: [
                  {
                    title: "预约活动",
                    id: 5,
                    parentId: 4,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "问卷采集",
                    id: 6,
                    parentId: 4,
                    expand: true,
                    selected: false,
                  },
                ],
              },
              {
                title: "商品预约",
                expand: true,
                selected: false,
                id: 7,
                parentId: 1,
                children: [
                  {
                    title: "预约商品",
                    id: 8,
                    parentId: 7,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "称分兑换",
                    id: 9,
                    parentId: 7,
                    expand: true,
                    selected: false,
                  },
                ],
              },
              {
                title: "服务",
                expand: true,
                selected: false,
                id: 10,
                parentId: 1,
                children: [
                  {
                    title: "社工",
                    id: 11,
                    parentId: 10,
                    expand: true,
                    selected: false,
                    children: [
                      {
                        title: "社工服务",
                        id: 111,
                        parentId: 11,
                        expand: true,
                        selected: false,
                      },
                      {
                        title: "社工活动",
                        id: 112,
                        parentId: 11,
                        expand: true,
                        selected: false,
                      },
                    ],
                  },
                  {
                    title: "居委",
                    id: 12,
                    parentId: 10,
                    expand: true,
                    selected: false,
                  },
                ],
              },
            ],
          },
        ],
        机构: [
          {
            title: "社区居委会",
            id: 1,
            parentId: 0,
            expand: true,
            selected: false,
          },
          {
            title: "街道办事处",
            id: 2,
            parentId: 0,
            expand: true,
            selected: false,
          },
          {
            title: "社工站",
            id: 3,
            parentId: 0,
            expand: true,
            selected: false,
          },
          {
            title: "党群中心",
            id: 4,
            parentId: 0,
            expand: true,
            selected: false,
          },
        ],
        行政区域: [
          {
            title: "广东省",
            expand: true,
            selected: false,
            id: 1,
            parentId: 0,

            children: [
              {
                title: "广州市",
                expand: true,
                selected: false,
                id: 1000,
                parentId: 1,
                children: [
                  {
                    title: "越秀区",
                    id: 2,
                    parentId: 1000,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "天河区",
                    id: 3,
                    parentId: 1000,
                    expand: true,
                    selected: false,
                  },
                ],
              },
              {
                title: "深圳市",
                expand: true,
                selected: false,
                id: 4,
                parentId: 1,
                children: [
                  {
                    title: "南山区",
                    id: 5,
                    parentId: 4,
                    expand: true,
                    selected: false,
                  },
                  {
                    title: "宝安区",
                    id: 6,
                    parentId: 4,
                    expand: true,
                    selected: false,
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    selectdMerge(a) {
      var c = [];
      a.map((d) => {
        this.mergeSelectd(c, d);
      });
      return c;
    },
    mergeSelectd(c, d) {
      //将选中的维度指标合并，逻辑有点复杂，看着就好
      if (d == null) return;
      if (c.length == 0) {
        c.push(JSON.parse(JSON.stringify(d)));
        return;
      }
      var ck = true;
      c.map((e) => {
        if (e.id == d.id) {
          var f = d.children;
          var g = e.children;
          if (g == null || g.length == 0) {
            if (f != null && f.length > 0)
              e.children = JSON.parse(JSON.stringify(f));
          } else {
            if (f != null && f.length > 0) this.mergeSelectd(e.children, f[0]);
          }
          ck = false;
          return;
        }
      });
      if (ck) {
        c.push(JSON.parse(JSON.stringify(d)));
      }
    },
    addParentSelected(allNode, childs, pitem) {
      //为选中维度加入父级从属层级

      if (pitem != null && allNode != null && childs != null) {
        childs.map((node) => {
          if (node.id == pitem.parentId) {
            var item = {
              title: node.title,
              expand: node.expand,
              selected: node.selected,
              id: node.id,
              parentId: node.parentId,
              label: node.label,
              value: node.value,
              align: "center",
              key:node.value,
              minWidth: 100,
              children: [JSON.parse(JSON.stringify(pitem))],
            };
            pitem = this.addParentSelected(allNode, allNode, item);
            return;
          } else {
            pitem = this.addParentSelected(allNode, node.children, pitem);
          }
        });
      }
      return pitem;
    },
    changeColumnSlt(res) {
      this.columnList = [];
      this.columnList = JSON.parse(JSON.stringify(this.dataList[res]));
      //var data = JSON.parse(JSON.stringify(this.dataList[res]));
      //this.moveChildSelected(data);
      //this.columnList = data;
    },
    changeRowSlt(res) {
      this.rowList = [];
      var data = JSON.parse(JSON.stringify(this.dataList[res]));
      this.moveChildSelected(data);
      this.rowList = data;
    },
    selectChangeColumn(datas, data) {
      this.moveParentSelected(datas, datas, data.parentId);
      this.moveChildSelected(data.children);
    },
    selectChangeRow(datas, data) {
      this.moveParentSelected(datas, datas, data.parentId);
      this.moveChildSelected(data.children);
    },
    moveParentSelected(datas, data, parentId) {
      if (data != null && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].id == parentId) {
            data[i].selected = false;
            this.moveParentSelected(datas, datas, data[i].parentId);
          } else {
            this.moveParentSelected(datas, data[i].children, parentId);
          }
        }
      }
    },
    moveChildSelected(data) {
      if (data != null && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          data[i].selected = false;
          this.moveChildSelected(data[i].children);
        }
      }
    },
    setLabelToSelected(data) {
      //设置label、value值
      if (data != null && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          data[i].label = data[i].title;
          data[i].value = data[i].id;
          this.setLabelToSelected(data[i].children);
        }
      }
    },
    viewReport() {
      if (this.columnSlt.slt == "") {
        this.$Message.error({
          content: "请选择横标选项",
          duration: 2,
          background: true,
        });
        return;
      }
      if (this.rowSlt.slt == "") {
        this.$Message.error({
          content: "请选择纵标选项",
          duration: 2,
          background: true,
        });
        return;
      }
      if (this.chartSlt.slt == "") {
        this.$Message.error({
          content: "请选择图表类型",
          duration: 2,
          background: true,
        });
        return;
      }
      this.columnSlt.list = this.getSelectedList(
        this.columnList,
        this.$refs.cList.getSelectedNodes()
      );

      if (this.columnSlt.list == null || this.columnSlt.list.length == 0) {
        this.$Message.error({
          content: "请选择横标维度",
          duration: 2,
          background: true,
        });
        return;
      }

      this.rowSlt.list = this.getSelectedList(
        this.rowList,
        this.$refs.rList.getSelectedNodes()
      );
      if (this.rowSlt.list == null || this.rowSlt.list.length == 0) {
        this.$Message.error({
          content: "请选择纵标维度",
          duration: 2,
          background: true,
        });
        return;
      }

      this.isCustom = false;
      //生成表头
      this.createTableHeader();
    },
    createTableHeader() {
      //生成表头
      var headers = [];
      var rowHeader = {
        minWidth: 100,
        title: this.rowSlt.slt,
        align: "center",
      };
      switch (this.rowSlt.slt) {
        case "时间": //时间
          if (
            this.rowSlt.list[0].title == "季" ||
            this.rowSlt.list[0].title == "周"
          ) {
            rowHeader.children = [
              { minWidth: 100, title: "日期", align: "center" },
              {
                minWidth: 100,
                title: this.rowSlt.list[0].title,
                align: "center",
              },
            ];
          }
          break;
        case "行政区域": //行政区域
        case "机构": //机构
        case "栏目": //栏目
        case "统计指标": //统计指标
          var count = this.countLavel(this.rowSlt.list);
          if (count > 1) {
            rowHeader.children = [];
            for (var i = 0; i < count; i++) {
              rowHeader.children.push({ minWidth: 100, title: " " });
            }
          }
          break;
      }
      headers.push(rowHeader);

      switch (this.columnSlt.slt) {
        case "时间": //时间
          switch (this.columnSlt.list[0].title) {
            case "年":
              for (var j = 1; j <= 12; j++) {
                headers.push({
                  minWidth: 100,
                  title: j + "月",
                  key: j,
                  align: "center",
                });
              }
              break;
            case "季":
              for (var j = 1; j <= 4; j++) {
                headers.push({
                  minWidth: 100,
                  title: "第" + j + "季度",
                  key: j,
                  align: "center",
                });
              }
              break;
            case "月":
              for (var j = 1; j <= 31; j++) {
                headers.push({
                  minWidth: 100,
                  title: j + "日",
                  key: j,
                  align: "center",
                });
              }
              break;
            case "周":
              for (var j = 1; j <= 4; j++) {
                headers.push({
                  minWidth: 100,
                  title: "第" + j + "周",
                  key: j,
                  align: "center",
                });
              }
              break;
            case "日":
              for (var j = 0; j <= 23; j++) {
                headers.push({
                  minWidth: 100,
                  title: j + "时",
                  key: j,
                  align: "center",
                });
              }
              break;
          }
          break;
        case "行政区域": //行政区域
        case "机构": //机构
        case "栏目": //栏目
        case "统计指标": //统计指标
         headers=[...headers,...this.columnSlt.list];
          break;
      }

      this.talbeColumns = headers;
    },
    countLavel(data) {
      //计算层级数
      var i = [];
      if (data == null || data.length == 0) return 0;
      data.map((item) => {
        i.push(1 + this.countLavel(item.children));
      });
      var count = Math.max(...i);
      return count;
    },
    getSelectedList(allNode, sNodes) {
      var list = [];
      if (sNodes == null || sNodes.length == 0) return list;
      var nodes = JSON.parse(JSON.stringify(sNodes));
      if ((nodes != null) & (nodes.length > 0)) {
        var list0 = [];
        nodes.map((item) => {
          if (item != null) {
            delete item.children;
            item.align = "center";
            var pitem = JSON.parse(JSON.stringify(item));
            pitem = this.addParentSelected(allNode, allNode, pitem);
            list0.push(pitem);
          }
        });
        list = this.selectdMerge(list0);
      }
      return list;
    },
    backReport() {
      this.isCustom = true;
    },
    saveReport() {},
  },
};
</script>
    
<style scoped lang='less'>
.clist {
  margin: 0 20px;
  .ctext {
    font-weight: bold;
  }
  .tree {
    width: 100%;
    height: calc((100vh - 340px) / 2);
    overflow: auto;
    background: #fff;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
  }
}
.searchStyle {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.searchTab {
  margin: 10px 20px 10px 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/.ivu-radio-border {
  background: #fff;
}

/deep/.ivu-tree-title-selected,
.ivu-tree-title-selected:hover {
  background-color: #2d8cf0 !important;
  padding: 5px 10px !important;
  color: #fff !important;
}
</style>